import IconEnvelope from "../svgs/IconEnvelope";
import { PropsViews } from "../model/PropsInterfaces";
import { FirmenResultSelect } from "../form/Selects";
import { getNameByWKIDNeu } from "../model/Kammern";
import { URL_IDP_LOGOUT, URL_NACHRICHTEN, getLink } from "../constants/urls";
import Cookies from "universal-cookie";

export default function AuthenticatedUserPopup(props: PropsViews) {
  const handleRoleSwitch = (e: any) => {
    const newRole = e.currentTarget.value;
    window.location.href = "/MeinWKOREST/b/login/roleswitch/blockchain/" + newRole;
  };

  const callLogout = () => {
    const cookies = new Cookies(null, { path: "" });
    cookies.set("logoutState", "calledLogout");
  };

  return (
    <>
      {props.userInfo && (
        <>
          <div className="user collapse nav-panel" id="User" aria-labelledby="Benutzerkontextmenü">
            <div className="user-nav-panel">
              <div className="user-details">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="user">
                    <h3 className="user-name">{props.userInfo?.authenticatedUserName}</h3>
                    <span className="email">{props.userInfo?.wkisEmail}</span>
                  </div>
                  <a
                    href={getLink(URL_NACHRICHTEN)}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-secondary"
                    type="button"
                  >
                    <IconEnvelope />
                  </a>
                </div>
                <FirmenResultSelect
                  userInfo={props.userInfo}
                  onChangeHandler={(e: any) => {
                    handleRoleSwitch(e);
                  }}
                />
                {props.userInfo?.authenticatedUserMemberId !== "0" && (
                  <div className="company d-flex justify-content-between mb-2" id="company">
                    <div className="details">
                      <span>
                        <strong> {props.userInfo?.authenticatedUserRoleName}</strong>
                      </span>
                      <span>Mitglied Nr. {props.userInfo?.authenticatedUserMemberId}</span>
                      <span>{getNameByWKIDNeu(props.userInfo?.authenticatedRole?.wirtschaftskammer || "1")}</span>
                    </div>
                  </div>
                )}
                <div className="add mt-4">
                  <a className="btn btn-secondary" href={getLink(URL_IDP_LOGOUT)} onClick={() => callLogout()}>
                    Abmelden
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
